"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractGuestOperationFields = void 0;
const guest_external_1 = require("../guest-external");
/**
 * Extract fields necessary to perform sorting and filtering operations on
 * `GuestExternal` records based on the information in the given `guest`.
 */
function extractGuestOperationFields(guest) {
    const fields = {};
    if ('name' in guest && guest.name.length > 0) {
        const id = 'id' in guest ? guest.id : (0, guest_external_1.extractGuestExternalId)(guest);
        // Different fields with the same value is necessary for searching and
        // filtering because `_sortGuestName` can not be used in a filter expression
        // because it will be a part of the primary key on the index
        fields._filterGuestName = guest.name.toLowerCase();
        fields._sortGuestName = `${guest.name.toLowerCase()}#${id}`;
    }
    if ('tags' in guest) {
        fields._flatTags = guest.tags.join(',').toLowerCase();
    }
    return fields;
}
exports.extractGuestOperationFields = extractGuestOperationFields;
