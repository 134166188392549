import { ComponentDefinition as CollectionListDefinition } from '@backstage-components/collection-list';
import { applyPropsDiff, createXmlRoot, computeId, familyTree, findCoreVariableObjects, isModuleVariables, swapInVariables, addXmlNode, collectionPageInstanceName, } from '@backstage/module-tree-shared';
import { arrayToRecord } from '@backstage/utils/array-helpers';
import { createValidator } from '@backstage/api-types';
function nodeToPageModule({ config, node, page, all, tree, vars = {}, groupInstanceData, xmlKit, parentXmlNode, dataContexts, }) {
    vars = Object.assign({}, vars);
    const module = all.modules[node.id];
    if (typeof module === 'undefined') {
        throw new Error(`module(${node.id}) does not exist`);
    }
    const core = all.cores[module.coreId];
    if (typeof core === 'undefined') {
        throw new Error(`core(${module.coreId}) does not exist`);
    }
    const component = all.components[core.componentId];
    if (typeof component === 'undefined') {
        throw new Error(`component(${core.componentId}) does not exist`);
    }
    const props = core.componentFieldData;
    if (typeof props !== 'object' || Array.isArray(props) || props === null) {
        throw new Error(`componentFieldData for Module(${node.id}) was not an object.`);
    }
    /*
     * Props are mainly stored in core.componentFieldData, but there are overrides
     * and one "underride" that's not been written yet.
     * -1 "presets" are set before core's props. But overrides can change the preset.
     *  0 core props "componentFieldData"
     *  1 moduleProps which are overrides at the module level (of the boss module)
     *  2 staffProps which are overrides at the group level. When we allow groups within
     *    groups, this will be layered more, but those will come through the same object.
     */
    // `groupInstanceData` is only to be passed down to modules within this instance
    // of this group, so we must establish a boundary for where overrides can be applies.
    // Currently, the only modules that have a path of length>0 are modules
    // that are simply situated in a group's open slot. Such a module CAN be
    // the boss module of another group instance, but not of this group instance,
    // so set groupInstanceData = null as a boundary.
    if (module.path.length > 0) {
        groupInstanceData = null;
    }
    const isBoss = core.coreType === 'group';
    // These only get populated by the top level of a group; staff modules or ungrouped will get nulls here.
    if (isBoss) {
        groupInstanceData = {
            groupId: core.id,
            groupModuleId: module.id,
            overrides: null,
            variableReferences: [],
        };
        if (isModuleVariables(module.variables)) {
            const vars = module.variables;
            if (vars?._instance) {
                const overrides = vars._version === 2 ? vars._instance.overrides : vars._instance;
                groupInstanceData = {
                    ...groupInstanceData,
                    overrides,
                };
            }
        }
    }
    // Pre-compute these for later.
    const mid = module.id;
    const groupModuleId = (groupInstanceData?.groupModuleId !== mid &&
        groupInstanceData?.groupModuleId) ||
        null;
    const htmlId = `${computeId({ mid, groupModuleId })}${dataContexts
        .map((x) => {
        return `--${x.instanceName}--${x.slug}`;
    })
        .join('')}`;
    const moduleProps = groupInstanceData?.overrides?.componentFieldData;
    // If overrides exist, see if any are applicable to this module.
    const staffProps = module.groupId
        ? groupInstanceData?.overrides?.[node.id] ?? null
        : null;
    // Determine the override set to use.
    const myOverrides = isBoss ? moduleProps : staffProps;
    // `props` is sometimes read-only, so always use the result of `applyPropsDiff`,
    // even if there are no overrides since it always returns a new, mutable object.
    const propsToUse = applyPropsDiff(props, myOverrides ?? null);
    const coreVariablesData = findCoreVariableObjects(propsToUse);
    const siteVariableLookup = page.siteVarLookup;
    const collectionLookup = page.collectionLookup;
    swapInVariables(coreVariablesData, siteVariableLookup, propsToUse, collectionLookup, dataContexts);
    // Since we're building up based on family tree, we don't need all this.
    // NEXT: variableResolver(vars, module.variables, core.variables, core.componentFieldData);
    // Unlike modules and cores, components don't need to be cloned, I think.
    // TODO: Add i18n to this.
    // const props = Object.assign(
    //   {},
    //   isJSONObject(core.componentFieldData) ? core.componentFieldData : {},
    //   isJSONObject(core.i18n?.[1]?.data) ? core.i18n?.[1]?.data : {},
    //   isJSONObject(core.i18n?.[0]?.data) ? core.i18n?.[0]?.data : {}
    // );
    const rv = {
        component: component.reactName,
        config: {
            scope: 'attendee',
            attendeeApiEndpoint: `${config.endpointBase}/attendee`,
            legacyEndpoint: config.legacyEndpoint,
            bitmovinKey: config.bitmovinKey,
            bitmovinAnalyticsKey: config.bitmovinAnalyticsKey,
            streamKey: config.streamKey,
            timestampEndpoint: `${config.endpointBase}/timestamp`,
            livekitEndpoint: config.livekitEndpoint,
        },
        id: htmlId,
        mid,
        cid: core.id,
        gid: module.groupId || null,
        groupModuleId,
        name: node.name,
        path: module.path || [],
        props: propsToUse,
    };
    page.pageModuleLookup[rv.id] = rv;
    node.groupCoreId = rv.gid;
    node.groupModuleId = rv.groupModuleId;
    node.htmlId = rv.id;
    node.slot = module.parentSlotSlug;
    const xmlNode = addXmlNode(parentXmlNode, node, xmlKit);
    /**
     * Add child modules and analogous xml nodes. This function is defined inside
     * another function to close over most of the variables that are needed.
     */
    const applyKids = (node, parentXmlNode, dataContexts) => {
        if ('kids' in node && node.kids) {
            rv.slots = rv.slots ?? {};
            node.kids.forEach((kid) => {
                const km = all.modules[kid.id];
                if (rv.slots && km?.parentSlotSlug) {
                    let slot = rv.slots[km.parentSlotSlug];
                    if (!slot) {
                        slot = rv.slots[km.parentSlotSlug] = [];
                    }
                    if (Array.isArray(slot)) {
                        slot.push(nodeToPageModule({
                            config,
                            node: kid,
                            page,
                            all,
                            tree,
                            vars,
                            groupInstanceData,
                            xmlKit,
                            parentXmlNode,
                            dataContexts,
                        }));
                    }
                }
            });
        }
    };
    /** Whether or not this is the special module `CollectionList` */
    const isCollectionList = component.reactName === 'CollectionList';
    if (!isCollectionList) {
        // Go straight to handling `kids`
        applyKids(node, xmlNode, dataContexts);
    }
    else if (createValidator(CollectionListDefinition.schema)(rv.props)) {
        // Handle the special case of `CollectionList`
        const { collection: modelId, instanceName } = rv.props;
        xmlNode.setAttribute('instanceName', instanceName);
        xmlNode.setAttribute('modelId', modelId);
        const items = page.collectionLookup?.[modelId]?.contentInstances ?? [];
        items.forEach((item) => {
            const itemElement = xmlKit.xmlDoc.createElement('Item');
            itemElement.setAttribute('instanceName', instanceName);
            itemElement.setAttribute('slug', item.slug);
            itemElement.setAttribute('modelId', modelId);
            xmlNode.appendChild(itemElement);
            const newDataContexts = [
                ...dataContexts,
                { modelId, slug: item.slug, instanceName },
            ];
            applyKids(node, itemElement, newDataContexts);
        });
    }
    else {
        console.error('CollectionList props did not validate:', rv.props);
    }
    return rv;
}
export function buildUpModules({ config, page, showId, domainName, siteVars, collections, location, }) {
    const all = {
        components: arrayToRecord(page.allComponents, 'id'),
        cores: arrayToRecord(page.allCores, 'id'),
        modules: arrayToRecord(page.allModules, 'id'),
    };
    const collectionLookup = arrayToRecord(collections ?? [], 'id');
    const rootModules = page.allModules
        .filter((x) => x.pageIndex !== null)
        .sort((a, b) => (a.pageIndex ?? 0) - (b.pageIndex ?? 0));
    const tree = familyTree(rootModules, all);
    const pageModuleLookup = {};
    const siteVarLookup = Object.fromEntries((siteVars ?? []).map((sv) => {
        let value = 'value' in sv ? sv.value : undefined;
        if ('source' in sv) {
            value = sv.source.uri;
        }
        if ('videoSource' in sv) {
            value = sv.videoSource;
        }
        if ('switchValue' in sv) {
            value = sv.switchValue;
        }
        if ('numberValue' in sv) {
            value = sv.numberValue;
        }
        return [sv.slug, value];
    }));
    const pageWithLookup = {
        ...page,
        pageModuleLookup,
        siteVarLookup,
        collectionLookup,
    };
    const structure = createXmlRoot({
        page: pageWithLookup,
        showId,
        domainName,
        mode: 'attendee',
    });
    if (typeof document === 'undefined' || typeof structure === 'undefined') {
        throw new Error('This function is only for use in the browser.');
    }
    const xmlKit = {
        page: pageWithLookup,
        xmlDoc: structure,
        all,
        mode: 'attendee',
    };
    const dataContexts = [];
    // `page.pathname` will look like "<modelSlug>/:slug" for collection pages.
    const [baseModelSlug, slugMarker] = page.pathname.slice(1).split('/');
    // The page's actual pathname will end with "<modelSlug>/<instanceSlug>" for collection pages.
    const [modelSlug, instanceSlug] = location?.pathname.split('/').slice(-2) ?? [];
    if (slugMarker === ':slug' && baseModelSlug && baseModelSlug === modelSlug) {
        const model = collections?.find((x) => x.slug === modelSlug);
        if (model) {
            // This is a Collection page and the model exists, so add the page-level
            // DataContext so content-managed values resolve properly.
            const { name, id } = model;
            dataContexts.push({
                instanceName: collectionPageInstanceName(name),
                slug: instanceSlug ?? 0,
                modelId: id,
            });
        }
    }
    const modules = tree.map((node) => nodeToPageModule({
        config,
        node,
        page: pageWithLookup,
        all,
        tree,
        xmlKit,
        parentXmlNode: structure.documentElement,
        dataContexts,
    }));
    return Object.assign({}, pageWithLookup, { modules, structure });
}
